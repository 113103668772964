import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = [ 'field', 'methodInput' ]
  
    initialize() {
      this.methodInputTarget.value = 'billplz';
    }
  
    using_paypal() {
      this.fieldTargets.forEach((el, i) => {
        el.classList.add('d-none');
      });
      this.methodInputTarget.value = 'paypal';
    }
  
    using_billplz() {
      this.fieldTargets.forEach((el, i) => {
        el.classList.add('d-none');
      });
      this.methodInputTarget.value = 'billplz';
    }

    using_curlec() {
      this.fieldTargets.forEach((el, i) => {
        el.classList.remove('d-none');
      });
      this.methodInputTarget.value = 'curlec';
    }
  }