import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ['methodInput']
  
    initialize() {
      this.methodInputTarget.value = '';
    }
    
    connect() {
      console.log('Testing');
    }
  
    method_one(event) {
      let element = event.currentTarget;
      let value = element.options[element.selectedIndex].value;
      
      this.methodInputTarget.value = value;
    }
  
    method_two(event) {
      let element = event.currentTarget;
      let value = element.options[element.selectedIndex].value;
      
      this.methodInputTarget.value = value;
    }
  }