import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'tracker' ]

  connect() {
    this.loadingFund()
  }

  loadingFund() {
    this.trackerTargets.forEach((el, i) => {
      //get each package price and funded amount
      let id = el.getAttribute('id')
      let funding_id = document.getElementById(`funding-${id}`)  
      let price_id = document.getElementById(`package-${id}`)
      let funding_price = funding_id.dataset.funding
      let package_price = price_id.dataset.funding

      //check screen size
      if (screen.width < 375) {
        let percentage = (funding_price / package_price * 206).toFixed(2)
        this.loadingColor(el, funding_price, package_price, percentage)
      } else {
        let percentage = (funding_price / package_price * 335).toFixed(2)
        this.loadingColor(el, funding_price, package_price, percentage)
      }
    })
  }

  //apply background color base on the collected fund by percentage
  loadingColor(el, funding_price, package_price, percentage) {
    el.style.borderRadius = '10px'
    if (funding_price !== package_price) {
      el.style.backgroundColor = '#2B7869';
      el.style.width = `${percentage}px`;
    } else {
      el.style.backgroundColor = ' #2B7869';
      el.style.width = '100%';
    }
  }
}