import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'btnSubmit', 'alert' ]

  setQty(event) {
    let ele = event.currentTarget
    let limit = ele.dataset.limit
    let id = ele.dataset.id
    let alert = document.getElementById(`pax-alert-${id}`)
    let btnSubmit = document.getElementById(`pax-btnSubmit-${id}`)

    if (ele.value === '') {
      ele.value = 0
    } else if ( ele.value > Number(limit) ) {
      if (this.hasBtnSubmitTarget && this.hasAlertTarget) {
        this.btnSubmitTarget.disabled = true
        this.alertTarget.innerHTML = ` Entry pax cannot be more than ${limit}.`
        this.alertTarget.classList.add('text-danger')
       } else {
        btnSubmit.disabled = true
        alert.innerHTML = ` Entry pax cannot be more than ${limit}.`
        alert.classList.add('text-danger')
      }
    } else {
      if (this.hasBtnSubmitTarget && this.hasAlertTarget) {
        this.btnSubmitTarget.disabled = false
        this.alertTarget.innerHTML = `The maximum of pax per slot is ${limit}, you can set it below ${limit} for each slot.`
        this.alertTarget.classList.remove('text-danger')
      } else {
        btnSubmit.disabled = false
        alert.innerHTML = `The maximum of pax per slot is ${limit}, you can set it below ${limit} for each slot.`
        alert.classList.remove('text-danger')
      }
    }
  }
}