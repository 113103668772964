import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ['allItem','allRegistryItem']
  
    initialize() {
      this.allItemTarget.value = '';
    }
    
    connect() {
      console.log('Registry Item');
      console.log(this.allItemTargets);
    }
  
    select_item(event) {
      event.preventDefault();
      let element = event.currentTarget;
      let value = element.value;
      
      if(element.classList.contains('active')) {
        element.classList.remove('active')
        element.innerHTML = 'Select';

        let a = document.getElementById(`input-${value}`);
        a.value = '';
        a.removeAttribute('data-target', 'registry-item.allItem');
      } else {
        element.classList.add('active')
        element.innerHTML = 'Selected';

        let a = document.getElementById(`input-${value}`);
        a.value = value;
        a.setAttribute('data-target', 'registry-item.allItem');
        
      }

      var empty = [];
      var empty_price = [];
      this.allItemTargets.forEach(function(el) {
        let a_price = el.dataset.price;
        empty_price.push(a_price);
        empty.push(el.value);
      })
      
      var list = [];
      for (var j = 0; j < empty_price.length; j++) {
        list.push({'empty': empty[j], 'empty_price': empty_price[j]});
      }

      list.sort(function(a, b) {
        return ((a.empty_price > b.empty_price) ? -1 : ((a.empty_price == b.empty_price) ? 0 : 1));
      });

      for (var k = 0; k < list.length; k++) {
        empty[k] = list[k].empty;
        empty_price[k] = list[k].empty_price;
      }

      console.log(empty_price);
      console.log(empty);
      this.allRegistryItemTarget.value = empty;
      
    }
  }